<template>
  <modal-template
    v-if="activeModal === USER_NOLOGIN_INFO"
    size="intermedium-small"
    :is-static="false"
    :personalized-style-container="getPersonalizedBox"
    @close="handleClose"
  >
    <wegow-loader v-if="loading" class="load-more-loader" :middle="true" />
    <div
      v-else
      id="containerBuyer"
      class="container-onboarding pb-8 md:mx-16 mx-8"
    >
      <h3 class="mb-8 text-center" :style="getPersonalizedTitle">
        {{ $t("wegow.login.addDataTitle") }}
      </h3>

      <form class="space-y-2" @submit.prevent="userData">
        <wegow-input
          id="email-signup"
          v-model:value="model.email"
          name="email"
          type="email"
          :placeholder="$t('wegow.login.email') + '*'"
          :error-message="valid.email ? '' : 'wegow.login.emailError'"
          :show-error-always="showErrorAlways"
          :validator="validators.email"
          :disabled="success"
          @valid="valid.email = $event"
        />
        <wegow-input
          id="profile-user-firstname"
          v-model:value="model.first_name"
          :placeholder="$t('wegow.profile.settings.firstName')"
          :show-error-always="showErrorAlways"
          :validator="validators.first_name"
          :disabled="success"
          @valid="valid.first_name = $event"
        />
        <wegow-input
          id="profile-user-lastname"
          v-model:value="model.last_name"
          :placeholder="$t('wegow.profile.settings.lastName')"
          :show-error-always="showErrorAlways"
          :validator="validators.last_name"
          :disabled="success"
          @valid="valid.last_name = $event"
        />
        <div class="pt-3 custom-control custom-checkbox">
          <wegow-checkbox
            id="terms-accepted-checkbox"
            v-model:value="model.terms_accepted"
            :html-title="true"
            :disabled="success"
            :error="showErrorAlways && !model.terms_accepted"
            :title="
              $t('wegow.login.termsAndConditions', {
                termsUrl: termsAndConditionsUrl,
                PrivacityUrl: PrivacyPolicyUrl,
                personalizedStyle: getPersonalizedUrlStyle
              })
            "
          />
        </div>
        <div class="flex justify-center">
          <wegow-button
            button-id="confirm-button"
            background="opacity"
            color="topaz"
            :text="
              success
                ? $t('wegow.purchase.listed')
                : $t('wegow.purchase.acceptWaitingList')
            "
            :disabled="isDisabled"
            type="submit"
            class="btn mt-6"
            :styles="getPersonalizedButton"
            @click-disabled="checkForm()"
          />
        </div>
      </form>
    </div>
  </modal-template>
  <modal-success
    v-if="success"
    :text="$t('wegow.purchase.inWaitingList')"
    @accept="onAccept()"
  />
</template>
<script setup>
// Vue
import { reactive, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

// Components
import WegowButton from "@/components/general/forms/WegowButton.vue";
import WegowInput from "@/components/general/forms/WegowInput.vue";
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import WegowLoader from "@/components/general/ui/WegowLoader.vue";
import WegowCheckbox from "@/components/general/forms/WegowCheckbox";
import ModalSuccess from "@/components/general/modals/ModalSuccess.vue";

// Composables
import { useIframeStyle } from "@/composables/useIframeStyle";
import { useEvent } from "@/composables/useEvent";
// import { useUser } from "@/composables/useUser";

// Plugins
import { useModalHandler } from "@/plugins/useModalHandler";

// Variables
const { details: event, addToWaitingListNoLogin } = useEvent();
const { activeModal, closeModal, USER_NOLOGIN_INFO } = useModalHandler();
const { t } = useI18n();
const router = useRouter();
const {
  getPersonalizedButton,
  getPersonalizedTitle,
  getPersonalizedBox,
  getPersonalizedAuxColor
} = useIframeStyle();

const success = ref(false);
const loading = ref(false);
const model = reactive({
  first_name: "",
  last_name: "",
  email: undefined,
  terms_accepted: false
});
const valid = reactive({
  email: false
});
const showErrorAlways = ref(false);
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
const validators = ref({
  email: { validate: email => EMAIL_REGEX.test(email || "") }
});

// Computed
const getPersonalizedUrlStyle = computed(() => {
  return Object.entries(getPersonalizedAuxColor.value)
    .map(([key, value]) => `${key}: ${value}`)
    .join("; ");
});
const termsAndConditionsUrl = computed(() => {
  return router.resolve({
    name: "TermsOfUsePage",
    params: { region: "es" }
  }).href;
});
const PrivacyPolicyUrl = computed(() => {
  return router.resolve({
    name: "PrivacyPolicyPage",
    params: { region: "es" }
  }).href;
});

// Methods
const handleClose = () => {
  closeModal();
};
const isDisabled = computed(
  () =>
    Object.values(valid).includes(false) ||
    !model.terms_accepted ||
    success.value
);
const checkForm = () => {
  showErrorAlways.value = true;
};

const userData = async () => {
  try {
    loading.value = true;
    const user_data = {
      email: model.email,
      name: model.first_name || "",
      last_name: model.last_name || "",
      ticket_types: []
    };
    await addToWaitingListNoLogin(event.value.id, user_data);
    success.value = true;
    closeModal();
  } catch (error) {
    // TODO: Control error
    throw new Error(error.message);
  } finally {
    setTimeout(() => {
      loading.value = false;
    }, 500);
  }
};
const onAccept = () => {
  success.value = false;
  location.reload();
};
</script>
<style lang="scss" scoped>
#containerBuyer {
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    @media screen and (min-width: $screen-md) {
      font-size: 30px;
    }
  }
  .or-title {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-size: 16px;
    z-index: 1;
    overflow: hidden;
    &:before,
    &:after {
      content: "\a0";
      position: absolute;
      top: 51%;
      width: 25%;
      height: 1px;
      background-color: $white;
      overflow: hidden;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
</style>
