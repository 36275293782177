<template>
  <modal-template
    :size="size"
    :success-error-dialog="successErrorDialog"
    :no-arrow="true"
    @close="closeModal"
  >
    <div class="row modal-success">
      <div class="col-12">
        <figure class="picture">
          <picture>
            <img
              :src="require('@/assets/images/ic-success-topaz-light.svg')"
              class="lazyload m-auto mb-3"
              alt="Modal error"
            />
          </picture>
        </figure>
      </div>
      <div class="col-12">
        <h3 v-if="!customContent" id="modal-error-text" class="mb-8">
          {{ text }}
        </h3>
        <slot v-if="customContent" name="content"></slot>
        <div class="buttons">
          <wegow-button
            :text="$t('general.accept')"
            size="base-large"
            color="topaz"
            background="transparent"
            @click="$emit('accept')"
          >
          </wegow-button>
        </div>
      </div>
    </div>
  </modal-template>
</template>

<script setup>
import ModalTemplate from "@/components/general/modals/ModalTemplate.vue";
import { useModalHandler } from "@/plugins/useModalHandler";
import WegowButton from "@/components/general/forms/WegowButton";
defineProps({
  img: {
    type: String,
    required: false,
    default: "sad"
  },
  text: {
    type: String,
    default: ""
  },
  successErrorDialog: {
    type: String,
    required: false,
    default: "success-error-dialog"
  },
  size: {
    type: String,
    required: false,
    default: "small"
  },
  customContent: {
    type: Boolean,
    required: false,
    default: false
  }
});
defineEmits(["accept", "close"]);

const { closeModal } = useModalHandler();
</script>

<style lang="scss" scoped>
a {
  margin-top: 12px;
  margin-bottom: 24px;
}
.modal-success {
  background-color: $backgroundBlack !important;
  padding: 12px 30px 22px 30px;
}
h3 {
  font-size: 16px;
}
/* .modal-success{
  padding: 25px 83px!important
} */
</style>
